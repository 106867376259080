html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a:active {
  outline: none;
}

:-moz-any-link:focus {
  outline: none;
}

:focus {
  -moz-outline-style: none;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.uk-lightbox-toolbar.uk-lightbox-caption {
  background-color: transparent;
  color: #f1f1f1;
}

.uk-lightbox-toolbar.uk-position-top.uk-transition-slide-top {
  background-color: transparent;
}

#colorbox,
#cboxWrapper {
  border: none;
  outline: none;
}

#facebook_link {
  display: block;
  width: 30px;
  height: 30px;
  background-color: #000;
}
@media (min-width: 960px) {
  #facebook_link {
    position: absolute;
    right: 0;
    top: 63px;
  }
}
#facebook_link img {
  width: 100%;
  height: auto;
}

html,
body {
  background-color: #020101;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #f1f1f1;
}

a.overviewlink {
  width: 200px;
  height: 286px;
  display: block;
  float: left;
  text-decoration: none;
}
a.overviewlink div {
  background-position: 0 0;
  background-repeat: no-repeat;
  border: 10px solid #333333;
  text-indent: -9999px;
  height: 242px;
  cursor: pointer;
}
a.overviewlink span {
  text-align: center;
  color: white;
  display: block;
  margin-top: 21px;
}
a.overviewlink span span {
  background: #333333;
  display: inline;
  padding: 4px 8px;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 12px;
}
a.overviewlink.tracks div {
  background-image: url(../images/overview3/our-side.png);
}
a.overviewlink.ug2 {
  width: 371px;
}
a.overviewlink.ug2 div {
  background-image: url(../images/overview3/ug2.png);
}
a.overviewlink.trainstories {
  width: 189px;
}
a.overviewlink.trainstories div {
  background-image: url(../images/overview3/trainstories2.png);
}
a.overviewlink.iff div {
  background-image: url(../images/overview3/flanders-fields.png);
}
a.overviewlink:hover div {
  border-color: #bf2027;
}
a.overviewlink:hover span span {
  background: #bf2027;
}
a.overviewlink:hover span span.sale {
  color: white;
}

.col2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.col2 img {
  margin: 0 10px 10px 0;
  float: left;
  width: 270px;
  height: auto;
}
.col2 img.even {
  margin-right: 0;
}

/* specific to this page */
#wrapper {
  width: 100%;
  max-width: 996px;
  margin: 0 auto;
  padding: 0 18px 60px 18px;
  position: relative;
}
@media (min-width: 960px) {
  #wrapper {
    max-width: 1032px;
    padding: 0 36px 60px 36px;
  }
}

#header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
  padding: 15px 0;
}
@media (min-width: 960px) {
  #header {
    border-bottom: 0 solid white;
    background-image: url(../images/header_lijntje.jpg);
    background-repeat: repeat-x;
    background-position: bottom center;
    padding: 0;
    height: 260px;
    position: relative;
    background-position: 0 165px;
  }
}
#header .righter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100px;
}

h1.logo {
  width: 55px;
  height: 41px;
  background-position: 0 0;
  background-image: url(../images/alias_press_logo.jpg);
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-size: cover;
}
@media (min-width: 720px) {
  h1.logo {
    width: 110px;
    height: 82px;
  }
}
@media (min-width: 960px) {
  h1.logo {
    position: absolute;
    top: 63px;
    left: 0px;
  }
}
h1.logo a {
  width: 100%;
  height: 100%;
  display: block;
  text-indent: -9999px;
}

#header > ul {
  width: 100%;
  height: 39px;
  top: 179px;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 10px;
}

.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
}
.rslides .slideholder {
  width: 100%;
  background-position: center top;
  background-size: 100% auto;
  display: block;
  background-repeat: no-repeat;
  height: 900px;
}
@media (min-width: 960px) {
  .rslides .slideholder {
    height: 450px;
  }
}
.rslides li {
  -webkit-backface-visibility: hidden;
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0;
}
.rslides li:first-child {
  position: relative;
  display: block;
  float: left;
}
.rslides img {
  display: block;
  height: auto;
  float: left;
  width: 100%;
  border: 0;
}

#header ul#menu li {
  width: 192px;
  height: 39px;
  margin: 0;
  padding: 0;
  position: relative;
}
#header ul#menu li ul a {
  background-position: 5px 100%;
}

#header ul#menu li.publications {
  width: 200px;
}

#header ul#menu li.blog {
  width: 189px;
}

#header ul#menu li.shop {
  width: 195px;
}

#header ul#menu li.about {
  width: 190px;
}

#header ul#menu li.contact {
  width: 171px;
}

#header ul#menu li a {
  text-indent: -9999px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 39px;
}

#header ul#menu li.publications a {
  background-image: url(../images/menu/publications.png);
  background-position: 100% 0;
}

#header ul#menu li.shop a {
  background-image: url(../images/menu/shop.png);
  background-position: 100% 0;
}

#header ul#menu li.about a {
  background-image: url(../images/menu/about.png);
  background-position: 100% 0;
}

#header ul#menu li.blog a {
  background-image: url(../images/menu/blog.png);
  background-position: 100% 0;
}

#header ul#menu li.contact a {
  background-image: url(../images/menu/contact.png);
  background-position: 100% 0;
}
#header ul#menu li.contact a.selected, #header ul#menu li.contact a:hover {
  background-position: 100% 100%;
}

#header ul#menu li.publications li {
  width: 240px;
  margin-left: -5px;
  height: 24px;
}

#header ul#menu li.publications li a {
  width: 240px;
  height: 24px;
}

#header ul#menu li.publications li a {
  background-position: 5px 100%;
}
#header ul#menu li.publications li a:hover {
  background-position: 5px 0;
}

#header ul#menu li.publications li a.ug2 {
  background-image: url(../images/menu/publications/ultimate-graffsportz-2.jpg);
}

#header ul#menu li.publications li a.iff {
  background-image: url(../images/menu/publications/in-flanders-fields.jpg);
}

#header ul#menu li.publications li a.tracks {
  background-image: url(../images/menu/publications/our-side-of-the-tracks.jpg);
}

#header ul#menu li.publications li a.trainstories {
  background-image: url(../images/menu/publications/train-stories.png);
}

#header ul#menu li.publications li a.ravan {
  background-image: url(../images/menu/publications/ravan.png);
}

#header .sf-menu ul {
  background-color: #020101;
}

#header ul#menu li a.selected,
#header ul#menu li a:hover {
  background-position: 100% 100%;
}

#sidebar {
  padding-left: 0px;
  width: 100%;
}
@media (min-width: 960px) {
  #sidebar {
    float: left;
    width: 310px;
    padding-right: 50px;
  }
}
#sidebar .slideshow-books {
  max-width: 400px;
}

#content {
  margin-top: 30px;
  position: relative;
}
@media (min-width: 720px) {
  #content {
    margin-top: 83px;
  }
}

#content.overview-page {
  background: #333;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}
@media (min-width: 720px) {
  #content.overview-page {
    padding: 20px;
  }
}
#content.overview-page a.overview {
  position: relative;
  margin: 0;
  width: 50%;
  display: block;
}
@media (min-width: 1032px) {
  #content.overview-page a.overview {
    width: 300px;
  }
}
#content.overview-page a.overview.overview-col-2 {
  width: 100%;
}
@media (min-width: 1032px) {
  #content.overview-page a.overview.overview-col-2 {
    width: 600px;
  }
}
#content.overview-page a.overview.overview-col-2 div.holder {
  padding-bottom: 75%;
}
#content.overview-page a.overview.ravan {
  width: calc(50% - 5px);
}
@media (min-width: 720px) {
  #content.overview-page a.overview.ravan {
    width: calc(50% - 10px);
  }
}
@media (min-width: 1032px) {
  #content.overview-page a.overview.ravan {
    width: 300px;
  }
}
#content.overview-page a.overview.train-stories {
  width: calc(50% - 5px);
}
@media (min-width: 720px) {
  #content.overview-page a.overview.train-stories {
    width: calc(50% - 10px);
  }
}
@media (min-width: 1032px) {
  #content.overview-page a.overview.train-stories {
    width: 300px;
  }
}
#content.overview-page a.overview.our-side-of-the-tracks {
  width: calc(50% - 5px);
  margin-left: 10px;
}
@media (min-width: 720px) {
  #content.overview-page a.overview.our-side-of-the-tracks {
    width: calc(50% - 10px);
    margin-left: 20px;
  }
}
@media (min-width: 1032px) {
  #content.overview-page a.overview.our-side-of-the-tracks {
    width: 300px;
    margin-left: 0;
  }
}
#content.overview-page a.overview.train-stories, #content.overview-page a.overview.our-side-of-the-tracks, #content.overview-page a.overview.tales-from-belgium {
  margin-bottom: 20px;
}
@media (min-width: 720px) {
  #content.overview-page a.overview.train-stories, #content.overview-page a.overview.our-side-of-the-tracks, #content.overview-page a.overview.tales-from-belgium {
    margin-bottom: 20px;
  }
}
@media (min-width: 1032px) {
  #content.overview-page a.overview.train-stories, #content.overview-page a.overview.our-side-of-the-tracks, #content.overview-page a.overview.tales-from-belgium {
    margin-bottom: 0;
  }
}
#content.overview-page a.overview.our-side-of-the-tracks-desktop {
  display: none;
}
@media (min-width: 1032px) {
  #content.overview-page a.overview.our-side-of-the-tracks-desktop {
    display: block;
  }
}
#content.overview-page a.overview.our-side-of-the-tracks-mobile {
  display: block;
}
@media (min-width: 1032px) {
  #content.overview-page a.overview.our-side-of-the-tracks-mobile {
    display: none;
  }
}
@media (min-width: 1032px) {
  #content.overview-page a.overview.ultimate-graffsportz-2, #content.overview-page a.overview.ravan, #content.overview-page a.overview.train-stories {
    margin-right: 20px;
  }
  #content.overview-page a.overview.our-side-of-the-tracks, #content.overview-page a.overview.ultimate-graffsportz-2 {
    margin-top: 20px;
  }
}
#content.overview-page a.overview.tales-from-belgium {
  position: relative;
}
#content.overview-page a.overview.tales-from-belgium div.holder {
  background-position: right center;
  background-size: auto 100%;
  position: relative;
  z-index: 10;
}
#content.overview-page a.overview.tales-from-belgium div.img-holder {
  display: block;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#content.overview-page a.overview.tales-from-belgium .hoverer {
  opacity: 0;
  width: 100%;
  height: 100%;
  top: auto;
  bottom: 0;
  z-index: 20;
}
#content.overview-page a.overview div.holder {
  width: 100%;
  height: 0;
  padding-bottom: 150%;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#content.overview-page a.overview .hoverer {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #bf2027;
  background: rgba(191, 32, 39, 0.925);
  display: block;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
@media (min-width: 720px) {
  #content.overview-page a.overview:hover .hoverer {
    opacity: 1;
  }
}
#content.overview-page a.overview .availability {
  text-align: center;
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
}
#content.overview-page a.overview .availability > span {
  border: 2px solid white;
  color: white;
  padding: 8px 15px;
  border-radius: 12px;
}
#content.overview-page a.overview .textholder {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 60px 55px;
}
#content.overview-page a.overview .textholder h1 {
  color: white;
  text-transform: uppercase;
  font-size: 21px;
  line-height: 30px;
}
#content.overview-page a.overview .textholder h2 {
  color: white;
  padding-top: 12px;
  margin-top: 12px;
  font-size: 14px;
  line-height: 21px;
  position: relative;
}
#content.overview-page a.overview .textholder h2:before {
  display: block;
  height: 2px;
  width: 60px;
  position: absolute;
  top: -1px;
  left: 50%;
  margin-left: -30px;
  content: " ";
  background: white;
}
#content.overview-page a.overview .hoverer-bg {
  display: table;
  width: 100%;
  height: 100%;
}

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

@media (min-width: 960px) {
  #tekst {
    width: 550px;
    float: left;
  }
}

#sidebar label,
#content p,
#about p {
  font-size: 11px;
  line-height: 17px;
  text-shadow: 0 1px 1px #aaa;
}

#contact p {
  color: white;
}

#content p span.retail {
  color: #009ec4;
  font-weight: bold;
  font-size: 11px;
  text-shadow: 0 0 0 transparent;
}

#content p span.release {
  background-color: #bf2027;
  color: white;
  font-weight: bold;
  font-size: 13px;
  text-shadow: 0 0 0 transparent;
  font-weight: normal;
  display: inline-block;
  padding: 2px 5px;
  margin: 3px 0 16px 0;
}

.bloggers #sidebar h3,
.bloggers #tekst h2 a,
#tekst h1 {
  font-size: 24px;
  font-weight: normal;
  text-shadow: 0 1px 1px #aaa;
  font-family: Arial, Helvetica, sans-serif;
  color: #f1f1f1;
}

.book-header h1 {
  font-size: 24px;
  font-weight: normal;
  text-shadow: 0 1px 1px #aaa;
  font-family: Arial, Helvetica, sans-serif;
  color: #f1f1f1;
}
.book-header h2 {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  margin-bottom: 0px;
  margin-top: 2px;
  color: #f1f1f1;
}

.bloggers #tekst h1 {
  margin-top: 2px;
}

.bloggers #tekst h1 a,
.bloggers #tekst h2 a {
  text-shadow: none;
  color: white;
  text-decoration: none;
}

.bloggers #tekst h1 a:hover,
.bloggers #tekst h2 a:hover {
  text-shadow: none;
  color: #bf2027;
}

.bloggers #tekst h3,
#tekst h2 {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  margin-bottom: 0px;
  margin-top: 2px;
  color: #f1f1f1;
}

.uk-lightbox-items > * > * {
  max-width: 100vw;
}
@media (min-width: 960px) {
  .uk-lightbox-items > * > * {
    max-width: 900px;
  }
}

.preview-slideshow {
  margin-top: 10px;
  margin-bottom: 10px;
}

div.lijntje {
  height: 30px;
  width: 100%;
  display: block;
  background-position: 0 10px;
  background-image: url(../images/header_lijntje.jpg);
  background-repeat: repeat-x;
}

p {
  font-size: 13px;
}

p a {
  color: #bf2027;
  text-shadow: 0 0px 0px #bf2027;
  text-decoration: none;
}

p a:hover {
  color: white;
  text-decoration: underline;
  text-shadow: 0 0px 0px #000;
}

::selection,
::-moz-selection {
  background-color: #bf2027;
  color: #ffffff;
}

div#moviepreview {
  margin: 10px 0 60px 0;
}

#contact,
#about {
  width: 960px;
  display: block;
  margin: 20px 0;
}

.has-js #contact {
  display: block;
  position: absolute;
  top: 132px;
  right: 42px;
}

.has-js #about {
  display: block;
  position: absolute;
  top: 44px;
  left: 160px;
}

a.buy {
  background-color: #bf2027;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: white;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
}

a.buy:hover {
  text-decoration: none;
  background-color: #191919;
}

div.clear {
  clear: both;
}

strong {
  font-weight: bold;
}

.has-js #contact {
  width: 700px;
  text-align: right;
  margin: 0;
}

.has-js #about {
  width: 700px;
  text-align: left;
  margin: 0;
}

a.direct {
  text-decoration: underline;
  color: white;
}

a.direct:hover {
  text-decoration: none;
  color: #bf2027;
}

.bloggers #sidebar {
  width: 310px;
  padding-right: 50px;
  margin-top: 3px;
}

.bloggers #sidebar ul {
  margin-bottom: 51px;
}

.bloggers #sidebar ul li a {
  font-size: 11px;
  line-height: 17px;
  color: #ffffff;
  text-decoration: none;
}

.bloggers #sidebar ul li a:hover {
  text-decoration: none;
  color: #bf2027;
}

.bloggers #tekst {
  width: 960px;
  padding-right: 0px;
  position: relative;
}

.post-single {
  clear: both;
  overflow: hidden;
  background-color: #212121;
  width: 280px;
  padding: 15px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  float: left;
  clear: right;
  margin-right: 15px;
  margin-bottom: 15px;
}

.post-meta {
  width: 150px;
  margin-left: 30px;
  margin-right: 20px;
  float: left;
}

.nav {
  margin-top: 38px;
}

.nav li {
  font-size: 11px;
  line-height: 17px;
  padding: 2px 0 0px 0;
  margin: 0 0 0px 0;
  height: 32px;
  background-position: 0 100%;
  background-image: url(../images/header_lijntje.jpg);
  background-repeat: repeat-x;
}

.post-content {
  float: left;
  margin-right: 0px;
  margin-bottom: 170px;
}

#sidebar input {
  background-color: #414141;
  border: none;
  padding: 2px 3px;
  width: 144px;
  color: white;
}

#sidebar input#searchsubmit {
  background-color: #bf2027;
  width: auto;
  margin-top: 6px;
  cursor: pointer;
}

#sidebar input#searchsubmit:hover {
  background-color: #ff0000;
}

#content p.posted_on {
  color: #333;
  margin-bottom: 23px;
  text-shadow: none;
  text-align: left;
}

#content p.posted_on a {
  color: #333;
  text-shadow: none;
}

.bloggers img.size-medium {
  margin: 20px 6px;
}

.newer-older a {
  color: white;
}

.newer-older {
  width: 100%;
  position: relative;
  display: block;
  height: 50px;
}

.newer-older p a:hover {
  background-color: #414141;
  text-decoration: none;
}

.older {
  width: 50%;
  display: block;
  position: relative;
  height: 50px;
  float: left;
}

.newer {
  float: left;
  height: 50px;
  width: 50%;
  display: block;
  position: relative;
}

.older p {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
}

.older p a {
  width: 100px;
  background-color: #bf2027;
  color: white;
  padding: 5px 3px 5px 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.newer p {
  float: left;
  clear: right;
  position: absolute;
  top: 0;
  text-align: right;
  right: 0;
}

.newer p a {
  padding: 5px 10px 5px 3px;
  background-color: #bf2027;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  width: 100px;
  color: white;
}

div.thumbshowers {
  margin: 10px 0;
}

div.thumbshowers img {
  margin-right: 6px;
}

a.menu-toggler {
  color: white;
  margin-left: 20px;
}

body.uk-offcanvas-container .menu-icon {
  display: none;
}
body.uk-offcanvas-container .close-icon {
  display: inline;
}

.close-icon {
  display: none;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#offcanvas-menu .uk-offcanvas-bar {
  background-color: #020101;
}
#offcanvas-menu ul.uk-nav-sub {
  padding: 5px 0 5px 0;
}
#offcanvas-menu .uk-close {
  color: white;
}
#offcanvas-menu .uk-nav {
  text-align: left;
  color: white;
}
#offcanvas-menu .uk-nav > li {
  margin-bottom: 20px;
}
#offcanvas-menu .uk-nav > li:last-child {
  margin-bottom: 0;
}
#offcanvas-menu .uk-nav li.contacter span,
#offcanvas-menu .uk-nav li.contacter a {
  text-transform: none;
}
#offcanvas-menu .uk-nav li span,
#offcanvas-menu .uk-nav li a {
  font-size: 14px;
  color: white;
  text-align: left;
  text-transform: uppercase;
}
#offcanvas-menu .uk-nav li a:hover {
  color: #bf2027;
}

.entry-tags,
.entry-tags span,
.entry-tags a {
  font-size: 12px;
  line-height: 17px;
}

/*# sourceMappingURL=style.css.map */